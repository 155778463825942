@import "ui/styles/2-tools/tools.action";
@import "ui/styles/2-tools/tools.media-query.scss";

.Button {
	@include button;

	&___selected {
		text-decoration: underline;
	}

	&___default {
		font-weight: var(--font-weight-normal);
	}

	&___primary {
		color: var(--color-black);
		border-color: var(--color-black);
		background: transparent;
	}

	&___secondary {
		color: var(--color-violet-light);
		border-color: var(--color-violet-light);
		background-color: transparent;
	}

	&___inheritsColor {
		color: currentColor;
		border-color: currentColor;
		background: transparent;
	}

	&___inactive,
	&:disabled {
		opacity: 0.3;
		pointer-events: none;

		&:hover,
		&:focus {
			transition: none;
		}
	}

	&___icon {
		min-width: 4rem;
		height: 2.4rem;
		padding: 0;
		display: flex;
		align-items: flex-end;
	}

	&___noAnimation {
		font-weight: var(--font-weight-normal);
	}

	&___filter {
		font-weight: var(--font-weight-normal);
		padding: 1.5rem 0;

		@include mq("md") {
			padding: 1.5rem;
		}
	}
}

.Button_textContainer {
	position: relative;
	overflow: hidden;
}

.Button_text {
	&___animationText {
		display: none;
	}
}

.Button___default,
.Button___primary,
.Button___secondary,
.Button___inactive,
.Button___filter,
.Button:disabled {
	.Button_text {
		transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
		position: relative;
		display: block;

		&___animationText {
			display: block;
			position: absolute;
			top: 0;
			transform: translateY(101%);
			transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
		}
	}

	&:hover {
		.Button_text {
			transform: translateY(-101%);

			&___animationText {
				transform: translateY(0%);
			}
		}
	}
}

.Button___arrowNext {
	transform: translate(-50%, 0);
	width: 70px;
	height: 60px;
	cursor: pointer;

	.Button_arrowContainer___arrowNext {
		content: "";
		position: absolute;
		width: 45%;
		left: 0;
		height: 2px;
		background-color: currentColor;
		transition: width 0.3s ease, right 0.3s ease, top 0.3s ease;

		&::after,
		&::before {
			content: "";
			position: absolute;
			width: 15px;
			height: 2px;
			right: -3px;
			background-color: currentColor;
			transition: right 0.3s ease;
		}

		&::after {
			top: -5px;
			transform: rotate(45deg);
			transition: top 0.3s ease, transform 0.3s ease;
		}

		&::before {
			top: 5px;
			transform: rotate(-45deg);
			transition: top 0.3s ease, transform 0.3s ease;
		}
	}
	&:hover {
		.Button_arrowContainer___arrowNext {
			width: 55%;
			&::after,
			&::before {
				right: -3px;
				width: 15px;
			}
			&::after {
				top: -5px;
			}
			&::before {
				top: 5px;
			}
		}
	}
}

.Button___arrowPrev {
	transform: translate(-50%, 0);
	width: 70px;
	height: 60px;
	cursor: pointer;
	margin-right: var(--spacing--2xl);

	.Button_arrowContainer___arrowPrev {
		content: "";
		position: absolute;
		right: 0px;
		width: 45%;
		height: 2px;
		background-color: currentColor;
		transition: width 0.3s ease, left 0.3s ease, top 0.3s ease;

		&::after,
		&::before {
			content: "";
			position: absolute;
			width: 15px;
			height: 2px;
			left: -3px;
			background-color: currentColor;
			transition: right 0.3s ease;
		}

		&::after {
			top: -5px;
			transform: rotate(-45deg);
			transition: top 0.3s ease, transform 0.3s ease;
		}

		&::before {
			top: 5px;
			transform: rotate(45deg);
			transition: top 0.3s ease, transform 0.3s ease;
		}
	}
	&:hover {
		.Button_arrowContainer___arrowPrev {
			width: 55%;
			&::after,
			&::before {
				left: -3px;
				width: 15px;
			}
			&::after {
				top: -5px;
			}
			&::before {
				top: 5px;
			}
		}
	}
}
