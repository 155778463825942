/* ------------------------------------ *\
		tools.colors
\* ------------------------------------ */

//used for component backgrounds

@mixin coloredComponent {
	&.u-color___white {
		background: var(--color-white);
		color: var(--color-black);
	}

	&.u-color___whiteGrey {
		background: var(--color-white-grey);
		color: var(--color-black);
	}

	&.u-color___lightGrey {
		background: var(--color-grey-lightest);
		color: var(--color-black);
	}
	// Theme: Kogp
	[data-theme="kogp"] {
		&.u-color___lightGrey {
			color: var(--color-primary);
		}
	}

	&.u-color___lightBlue {
		background: var(--color-blue-lightest);
		color: var(--color-black);
	}

	&.u-color___blue {
		background: var(--color-blue);
		color: var(--color-light);
	}

	&.u-color___violet {
		background: var(--color-violet);
		color: var(--color-light);
	}

	&.u-color___green {
		background: var(--color-green);
		color: var(--color-light);
	}

	&.u-color___red {
		background: var(--color-red);
		color: var(--color-light);
	}
}

@mixin coloredComponentText {
	.u-color___white &,
	.u-color___whiteGrey &,
	.u-color___lightGrey &,
	.u-color___lightBlue & {
		color: var(--color-black);
	}
	.u-color___blue &,
	.u-color___violet &,
	.u-color___green &,
	.u-color___red & {
		color: var(--color-light);
	}
}
