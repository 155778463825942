@import "ui/styles/2-tools/tools.action";

.LinkButton {
	@include button;
	display: inline-block;

	&___sm {
		font-size: var(--font-size--sm);
	}

	&___primary {
		color: var(--color-black);
		border-color: var(--color-black);
	}

	&___secondary {
		color: var(--color-violet-light);
		border-color: var(--color-violet-light);
	}

	&___inheritsColor {
		color: currentColor;
		border-color: currentColor;
	}

	&___inactive,
	&:disabled {
		opacity: 0.3;
		pointer-events: none;

		&:hover,
		&:focus {
			transition: none;
		}
	}
}

.LinkButton_textContainer {
	position: relative;
	overflow: hidden;
}

.LinkButton_text {
	&___animationText {
		display: none;
	}
}

.LinkButton___primary,
.LinkButton___default,
.LinkButton___secondary,
.LinkButton___inactive,
.LinkButton___filter,
.LinkButton:disabled {
	.LinkButton_text {
		transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
		position: relative;
		display: block;

		&___animationText {
			display: block;
			position: absolute;
			top: 0;
			transform: translateY(101%);
			transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
		}
	}

	&:hover {
		.LinkButton_text {
			transform: translateY(-101%);

			&___animationText {
				transform: translateY(0%);
			}
		}
	}
}
// Theme: Kogp
[data-theme='kogp'] {
	.LinkButton_textContainer {
		line-height: 1.1;
	}
}