@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.aspect-ratio.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.colors.scss";

.CaseSliderItem {
	@include coloredComponent;
	box-sizing: border-box;
	color: var(--color-black);
	background: var(--color-white);
}

.CaseSliderItem_wrapper {
	padding: var(--spacing--5xl) 0;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: center;

	@include mq("md") {
		order: 0;
		padding: var(--spacing--5xl) 0 var(--spacing--4xl) 0;
	}
}

.CaseSlider_kicker {
	order: 1;
	flex-basis: 100%;

	@include mq("md") {
		order: 0;
		flex-basis: auto;
	}
}

.CaseSlider_link {
	order: 2;
	flex-basis: 100%;
	align-items: center;
	display: flex;
	gap: var(--spacing--xs);

	@include mq("md") {
		order: 0;
		flex-basis: auto;
		margin-left: var(--spacing--4xl);
		margin-right: auto;
	}
}

.CaseSlider_link___noKicker {
	margin-left: 0;
}

.CaseSliderItem_content {
	width: 100%;
	height: 100%;
	padding: var(--spacing--3xl) 0;
	order: 1;

	@include mq("md") {
		padding: var(--spacing--4xl) 0 0 0;
	}
}

.CaseSliderItem_heading {
	width: 100%;
	order: 1;
	margin-bottom: var(--spacing--xl);
	flex-basis: 100%;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	gap: 0;

	@include mq("md") {
		flex-direction: row;
		order: 0;
		margin-bottom: var(--spacing--2xl);
		align-items: flex-end;
		gap: var(--spacing--base);
	}
}

.CaseSliderItem_navigation {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	order: 0;
	margin-bottom: var(--spacing--3xl);

	@include mq("md") {
		justify-content: flex-end;
		margin-bottom: 0;
		flex-basis: 20%;
		align-self: flex-end;
	}
}
.CaseSliderItem_button {
	max-width: 18rem;
	width: 100%;
	margin-top: var(--spacing--xl);
	
	@include mq("md") {
		margin-top: 0;
	}
}

.CaseSliderItem_imageWrapper {
	display: flex;
	flex-direction: column;
	
}

.CaseSliderItem_image {
	@include aspect-ratio(65, 56);

	@include mq("md") {
		@include aspect-ratio(169, 75);
	}
}
