@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.aspect-ratio.scss";

.Picture {
	display: block;
	width: 100%;
	height: 100%;

	&.isFallback {
		background-position: center center;
		background-repeat: no-repeat;
		top: 0;
		left: 0;

		&.Picture___cover {
			background-size: cover;
		}
		&.Picture___contain {
			background-size: contain;
		}
	}

	&___desktopNoShow {
		display: block;
		@include aspect-ratio(65, 56);

		@include mq("md") {
			display: none;
		}
	}

	&___mobileNoShow {
		display: none;

		@include mq("md") {
			@include aspect-ratio(92, 83);
			display: block;
		}
	}
}

.Picture_asset {
	display: block;
	width: 100%;
	height: 100%;
	object-position: center;

	.Picture___cover & {
		object-fit: cover;
	}

	.Picture___contain & {
		object-fit: contain;
	}

	&___isSvg {
		object-fit: contain;
	}

	&___roundBorder {
		border-radius: 50%;
	}
}

[data-theme='kogp'] {
	.Picture___contain  {
		.Picture_asset {
			object-position: right;
		}
	}
}
