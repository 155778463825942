@import "ui/styles/2-tools/tools.media-query.scss";

.Kicker {
	color: currentColor;
	text-align: left;
	font-size: var(--font-size--xs);

	@include mq("md") {
		font-size: var(--font-size--lg);
	}
}

.Kicker_kickerFirst {
	font-weight: var(--font-weight--semibold);
	margin-right: 0.3rem;
	color: currentColor;
}

.Kicker_kickerSecond {
	color: currentColor;
}

// Theme: Kogp
[data-theme="kogp"] {
	.Kicker_kickerFirst {
		font-weight: var(--font-weight--extrablack);
	}
}
