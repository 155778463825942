/* ------------------------------------ *\
		tools.grid-mixins
\* ------------------------------------ */

@import "../2-tools/tools.display-mixins";
@import "ui/styles/2-tools/tools.media-query.scss";

// Grid based on BlazeUI's grid
// More info: http://blazeui.com/objects/grid/

@mixin grid {
	@include display-flex;
}

@mixin grid--wrap {
	flex-wrap: wrap;
}

@mixin grid--top {
	align-items: flex-start;
}

@mixin grid--left {
	justify-content: flex-start;
}

@mixin grid--right {
	justify-content: flex-end;
}

@mixin grid--center {
	align-items: center;
}

@mixin grid--horizontal-center {
	width: 100%;
	justify-content: center;
}

@mixin grid--bottom {
	align-items: flex-end;
}

@mixin grid__cell {
	flex: 1;
	padding-right: var(--grid-gutter);
	padding-left: var(--grid-gutter);
}

@mixin grid__cell--no-gutter {
	padding-right: 0.001em;
	padding-left: 0.001em;
}

@mixin grid__cell--large-gutter {
	padding-right: var(--grid-gutter);
	padding-left: var(--grid-gutter);

	@include mq("lg") {
		padding-right: var(--grid-gutter--lg);
		padding-left: var(--grid-gutter--lg);
	}
}

@mixin grid__cell--row-gap {
	padding-bottom: calc(var(--grid-gutter) * 2);
}

@mixin grid__cell--top {
	align-self: flex-start;
}

@mixin grid__cell--center {
	align-self: center;
	margin: 0 auto;
}

@mixin grid__cell--bottom {
	align-self: flex-end;
}

@mixin grid__cell--width-fixed {
	flex: 0 1 auto;
}

@mixin grid__cell--fit {
	flex: 1;
}

@mixin grid--full {
	flex-wrap: wrap;
}

@mixin grid__cell--full {
	flex: 0 0 100%;
	max-width: 100%;
	margin-left: 0;
}

@mixin grid__cell--auto {
	flex: auto;
}

@mixin grid__cell--hidden {
	@include display-none;
}

@mixin grid__cell--visible {
	@include display-initial;
}

@mixin grid__cell--width($width) {
	flex: 0 0 $width;
	max-width: $width;
	width: $width;
}

@mixin grid__cells {
	@each $width, $fraction in $grid-widths {
		&--width-#{$width} {
			@include grid__cell--width($fraction * 100%);
		}
	}
}
 
// might need some optimisation later on, when we find out exactly which screens sizes are we doing the offset for
@mixin grid__responsive-cells($screen-width) {
	@each $width, $fraction in $grid-widths {
		&--hidden\@#{$screen-width} {
			@include grid__cell--hidden;
		}

		&--visible\@#{$screen-width} {
			@include grid__cell--visible;
		}

		&--width-#{$width}\@#{$screen-width} {
			@include grid__cell--width($fraction * 100%);
		}

		&--offset-#{$width}\@#{$screen-width} {
			margin-left: $fraction * 100%;
		}

		&--width-fixed\@#{$screen-width} {
			@include grid__cell--width-fixed;
		}

		&--offsetLeft-#{$width}\@#{$screen-width} {
			margin-left: $fraction * 100%;
			margin-right: auto;
		}
		
		&--offsetRight-#{$width}\@#{$screen-width} {
			margin-right: $fraction * 100%;
			margin-left: auto;
		}
	}

	@each $width, $fraction in $grid-col-widths {
		@each $offset, $fraction2 in $grid-col-widths {
			&--stretch-#{$width}-#{$offset}\@#{$screen-width} {
				max-width: none;
				width: auto;
				min-width: min(calc(100% - ($fraction2 * 100%)), $fraction * 162.8rem);
				// 162.8rem is the max-width of the grid(if we substract the margins)
				
				.TopLevelGrid___scaleDown > & {
					background: red;
					// @include grid__cell--scale-down;
				}
			}
		}
	}
}


@mixin grid__cell--scale-down {
	 
}